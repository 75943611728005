<template>
  <div class="col-12">
   <SummaryReportComponent />
    <div class="row mb-5">
      <div class="col-12">
        <CashierDashboarOrderManagement/>
      </div>
    </div>
  </div>
</template>
<script>
import CashierDashboarOrderManagement from "./componets/CashierDashboarOrderManagement";
import SummaryReportComponent from "@/views/dashboard/componets/SummaryReportComponent";

export default {
  name: 'CashierDashboardPage',
  components: {SummaryReportComponent, CashierDashboarOrderManagement},
}
</script>
<style lang="scss" scoped></style>
