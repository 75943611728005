<template>
  <div class="col-12">
    <h5 class="font-weight-bold">
      Sales Per Order
    </h5>
    <hr>
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>
          Order
        </th>
        <th>
          Created Date
        </th>
        <th>
          Status
        </th>
        <th>
          Price
        </th>
      </tr>
      </thead>
      <tbody>
        <OrderListItemRowComponent
            :key="item.uuid"
            v-for="item in data"
            :item="item"
        />
      <tr>
        <td colspan="3">
          Total
        </td>
        <td>
          {{ total }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {currencyFormater, dateFormatter} from "@/utils/helper";
import OrderListItemRowComponent from "@/views/dashboard/reports/components/OrderListItemRowComponent";

export default {
  name: 'SalesPerOrderComponent',
  components: {OrderListItemRowComponent},
  computed: {
    ...mapState('restoReport', ['data']),
    total() {
      let total = 0
      this.data.map((v) => {
        total += v.amount - v.discount_amount
      })

      return currencyFormater(total)
    }
  },

}
</script>
<style lang="scss" scoped></style>
