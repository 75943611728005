<template>
  <div
      :class="[className]"
      class="card shadow border-0">
    <div class="card-header text-white font-weight-bold">
      {{ titleCard }}
    </div>
    <div class="text-white">
      <table class="table table-bordered text-white mb-0">
        <tr>
          <td>
            Created Date
          </td>
          <td>
            {{ dateFormater(orderData.created_at, true) }}
          </td>
        </tr>
        <tr>
          <td>
            Paid Date
          </td>
          <td>
            {{ orderData.invoice ? dateFormater(orderData.invoice.created_at, true) : '-' }}
          </td>
        </tr>
        <tr>
          <td>
            Cashier
          </td>
          <td>
            {{ orderData.user ? orderData.user.name : '-' }}
          </td>
        </tr>
      </table>
      <div class="card-body">
       <PrintBillButtonComponent />
      </div>
    </div>

  </div>
</template>
<script>
import orderMixins from "@/mixins/OrderMixins";
import {currencyFormater, dateFormatter} from "@/utils/helper";
import PrintBillButtonComponent from "@/views/dashboard/orders/components/PrintBillButtonComponent";

export default {
  name: 'CashierOrderPaymentInformationComponent',
  components: {PrintBillButtonComponent},
  props: {
    orderData: {
      type: Object,
      required: true
    },
    orders: {
      type: Array,
      required: true
    }
  },
  mixins: [orderMixins],
  data() {
    return {
      isShowModalPrint: false
    }
  },
  computed: {
    className() {
      if (this.orderData.status === 2) return 'bg-success'
      return 'bg-danger'
    },
    titleCard() {
      if (this.orderData.status === 2) return 'PAID'
      return 'UNPAID'
    }
  },
  methods: {
    dateFormater(date) {
      return dateFormatter(date)
    },
  },
}
</script>
<style lang="scss" scoped>
td {
  padding: .75rem;
}
</style>
