<template>
  <ModalComponent
      :is-show="true"
      :is-center="true"
      :modal-class="`modal-lg`"
      :is-header="true"
      :title="title"
      @close="$emit('close')"
  >
    <template v-slot:body>
      <form @submit.prevent="handleSubmit" class="form text-dark text-start">
        <div class="form-group">
          <label for="">
            Menu Name
          </label>
          <input type="text" class="form-control" v-model="data.name" required>
        </div>
        <div class="form-group">
          <label for="">
            Menu Description
          </label>
          <textarea class="form-control" v-model="data.description" rows="3"></textarea>
        </div>
        <div class="form-group">
          <label for="">
            Price
          </label>
          <input type="number" class="form-control" v-model="data.price" required>
        </div>
        <div class="form-group">
          <label for="">
            Category
          </label>
          <select class="form-control" v-model="data.child_category">
            <option v-for="item in categories" :key="item.label" :value="item.value">
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <button
              v-if="!isLoading"
              class="btn btn-primary btn-sm me-3">
            Save
          </button>
          <button
              v-if="isLoading"
              class="btn btn-sm btn-primary me-3">
            <LoadingComponent/>
          </button>
          <div @click="$emit('close')" class="btn btn-danger btn-sm">Cancel</div>
        </div>
      </form>
    </template>
  </ModalComponent>
</template>
<script>
import ModalComponent from "@/components/ModalComponent";
import {triggerErrorNotification, triggerSuccessNotification} from "@/utils/helper";
import {createMenu, getMenuCategoryList, updateMenu} from "@/services/CashierService";
import LoadingComponent from "@/components/LoadingComponent";
import {category} from "@/constant/foodCategory";
import {mapMutations, mapState} from "vuex";

export default {
  name: 'ModalAddCustomMenuButtonComponent',
  components: {LoadingComponent, ModalComponent},
  props: {
    title: {
      type: String,
      default: 'Add Custom Menu'
    },
    dataMenu: {
      type: Object,
      default: null
    }
  },
  watch: {
    dataMenu(val) {
      if(val) {
        this.data = {...val}
        this.data.description = this.data.meta.description
      }
    }
  },
  data() {
    return {
      isLoading: false,
      data: {
        name: '',
        description: '',
        price: '',
        child_category: ''
      },
    }
  },
  computed: {
    ...mapState('resto', ['categoryMenu']),
    categories() {
      return this.categoryMenu
    }
  },
  methods: {
    ...mapMutations('resto', ['setCategoryMenu']),
    async getMenuCategoryList() {
      this.isLoading = true
      try {
        const response = await getMenuCategoryList({
          type: 'menu'
        })
        this.setCategoryMenu(response.data.data)

      } catch (e) {
        triggerErrorNotification(e)
      }
      this.isLoading = false
    },
    async handleSubmit() {
      if (!this.isLoading) {
        this.isLoading = true
        try {
          const {name, description, price, child_category} = this.data

          if (!name) throw new Error('Name is required')
          if (!price) throw new Error('Price is required')
          if (!child_category) throw new Error('Category is required')

          const cat = this.categoryMenu.find((v) => {
            return v.value === child_category
          })

          if(this.dataMenu) {
            await updateMenu(
                {
                  uuid: this.data.uuid,
                  name,
                  description,
                  price,
                  category: cat.parent,
                  child_category
                }
            )
          } else {
            await createMenu({
              name,
              description,
              price,
              category: cat.parent,
              child_category
            })
          }

          triggerSuccessNotification('Menu Created', () => {
            window.location.reload()
          })
        } catch (e) {
          triggerErrorNotification(e)
        }
        this.isLoading = false
      }
    }
  },
  mounted() {
    if(this.dataMenu) {
      this.data = {...this.dataMenu}
      this.data.description = this.data.meta.description
    }
    this.getMenuCategoryList()
  }
}
</script>
<style lang="scss" scoped></style>
