import {createStore} from 'vuex'
import resto from './resto_store'
import restoReport from './resto_report'

export default createStore({
  state: {
    userData: null
  },
  mutations: {
    setUserData(state, value) {
      state.userData = value
    }
  },
  actions: {},
  modules: {
    resto: {
      namespaced: true,
      ...resto
    },
    restoReport: {
      namespaced: true,
      ...restoReport
    }
  }
})
