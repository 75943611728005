<template>
  <div class="btn-absolute">
    <button
        @click.prevent="isShow = !isShow"
        class="btn btn-sm btn-primary">
      <i class="fa fa-edit"></i>
    </button>
    <ModalAddCustomMenuButtonComponent
        v-if="isShow"
        @close="handleClose"
        :title="`Edit menu`"
        :data-menu="data"
    />
  </div>
</template>
<script>
import ModalAddCustomMenuButtonComponent
  from "@/views/dashboard/create_order/components/ModalAddCustomMenuButtonComponent";

export default {
  name: 'ButtonEditMenuComponent',
  components: {ModalAddCustomMenuButtonComponent},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
      this.isShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-absolute {
  position: absolute;
  top: 5px;
  right: 15px;
}
.btn {
  font-size: .7rem;
}
</style>
