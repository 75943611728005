<template>
  <transition name="fade">
    <div
      v-if="isShow"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      @click.self="handleClickBg"
    >
      <div
        :class="[
          isScrollable ? 'modal-dialog-scrollable' : '',
          isCenter ? 'modal-dialog-centered' : '',
          size,
          modalClass,
        ]"
        class="modal-dialog"
        role="document"
      >
        <div class="modal-content no-radius">
          <div
            v-if="isHeader"
            :class="[headerClass]"
            class="modal-header no-radius"
          >
            <h5 class="modal-title">
              {{ title ? title : '' }}
            </h5>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div v-if="isFooter" class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'ModalComponent',
  props: [
    'modalClass',
    'headerClass',
    'isShow',
    'isFooter',
    'isHeader',
    'title',
    'isScrollable',
    'isCenter',
    'size',
  ],
  methods: {
    handleClickBg() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.modal {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
