<template>
  <div class="card shadow border-none">
    <div class="row">
      <div class="col-8">
        <div class="card-body">
          <div class="row">
            <div class="col-6 mb-3">
              <strong>Menu List</strong>
            </div>
            <div class="col-6 mb-3 text-end">
              <button
                  @click="isShowAddCategory = true"
                  class="btn btn-sm btn-info">
                <i class="fa fa-plus"></i> Category
              </button>
            </div>
            <div class="col-12 text-start">
              <button
                  v-for="item in buttons" :key="item.label"
                  @click="handleInput(item.value)"
                  :class="[ item.isActive ? 'btn-warning' :'btn-secondary']"
                  type="button" class="btn btn-sm me-2 mb-2">
                {{ item.label }}
              </button>
            </div>
            <div class="col-12">
              <hr>
            </div>
            <div class="col-9 mb-3">
              <input type="text" class="form-control form-control-sm" v-model="keyword" placeholder="Search Menu">
            </div>
            <div class="col-3 mb-3">
              <AddCustomMenuButtonComponent
                  @close-add-menu="getMenuList"
              />
            </div>
            <div class="menu-container row">
              <CashierMenuListItemComponent
                  v-for="(val,key) in data"
                  :data="val"
                  :key="key"
              />
            </div>
          </div>
          <LoadingComponent v-if="isLoading"/>
        </div>
      </div>
      <div class="col-4 bg-summary pl-0">
        <CashierOrderSummaryComponent/>
      </div>
    </div>
    <ModalAddProductCategory
      v-if="isShowAddCategory"
      @close="handleClose"
    />
  </div>
</template>
<script>
import {getMenuCategoryList, getMenus} from "@/services/CashierService";
import {mapMutations, mapState} from "vuex";
import CashierMenuListItemComponent from "./CashierMenuListItemComponent";
import CashierOrderSummaryComponent from "./CashierOrderSummaryComponent";
import LoadingComponent from "@/components/LoadingComponent";
import {triggerErrorNotification} from "@/utils/helper";
import AddCustomMenuButtonComponent from "@/views/dashboard/create_order/components/AddCustomMenuButtonComponent";
import ModalAddProductCategory from "@/components/ModalAddProductCategory.vue";

export default {
  name: 'CashierMenuListComponent',
  components: {
    ModalAddProductCategory,
    AddCustomMenuButtonComponent,
    LoadingComponent, CashierOrderSummaryComponent, CashierMenuListItemComponent
  },
  data() {
    return {
      isLoading: true,
      isEdit: true,
      keyword: '',
      menus: [],
      isShowAddCategory: false
    }
  },
  computed: {
    ...mapState('resto', ['orderSummary', 'categoryMenu']),
    data() {
      const {type} = this.query
      let data = this.menus
      if (type) {
        data = data.filter((v) => {
          return v.child_category === type
        })
      }
      if (this.keyword) {
        data = data.filter((v) => {
          return v.name.toLowerCase().includes(this.keyword.toLowerCase())
        })
      }
      return data
    },
    query() {
      return this.$route.query
    },
    buttons() {
      const data = [
        {
          label: 'All',
          value: '',
          parent: '',
          isActive: false
        },
      ]
      this.categoryMenu.map((v) => {
        v.isActive = false
        data.push(v)
      })
      data.map((v) => {
        v.isActive = this.query.type === v.value
        return v
      })

      return data
    }
  },
  methods: {
    ...mapMutations('resto', ['setCategoryMenu']),
    handleClose() {
      this.isShowAddCategory = false
      window.location.reload()
    },
    async getMenuCategoryList() {
      this.isLoading = true
      try {
        const response = await getMenuCategoryList({
          type: 'menu'
        })
        this.setCategoryMenu(response.data.data)

      } catch (e) {
        triggerErrorNotification(e)
      }
      this.isLoading = false
    },
    handleInput(e) {
      this.$router.push({
        routeName: 'AddOrderScreen',
        query: {
          type: e
        }
      })
    },
    async getMenuList() {
      this.isLoading = true
      try {
        const res = await getMenus({})
        if (res.status === 200) {
          this.menus = res.data.data
        }
      } catch (e) {
        triggerErrorNotification(e)
      }
      this.isLoading = false
    }
  },
  mounted() {
    this.getMenuList()
    this.getMenuCategoryList()
  }
}
</script>
<style lang="scss" scoped>
.card {
  overflow: hidden;
}

.bg-summary {
  background-color: #256ab7;
}

.menu-container {
  max-height: 530px;
  overflow-y: scroll;
}
</style>
