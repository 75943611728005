<template>
  <ModalComponent
      :is-show="true"
      :is-center="true"
      :is-header="true"
      :title="`Add Product Category`"
      @close="$emit('close')"
  >
    <template v-slot:body>
      <div class="w-100">
        <form @submit.prevent="handleSubmit">
          <div class="form-group mb-3">
            <label for="" class="col-form-label">
              Title
            </label>
            <input type="text" v-model="data.label" required class="form-control">
          </div>
          <div class="form-group mb-3">
            <label for="" class="col-form-label">
              Type
            </label>
            <select v-model="data.parent" class="form-control">
              <option
                  v-for="item in type"
                  :key="item.label"
                  :value="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>
          <div class="form-group mb-3">
            <button
                :disabled="isLoading"
                class="btn btn-primary">
              Save
            </button>
          </div>
        </form>
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import ModalComponent from "@/components/ModalComponent.vue";
import {triggerErrorNotification, triggerSuccessNotification} from "@/utils/helper";
import {createCategoryMenu} from "@/services/CashierService";

export default {
  name: 'ModalAddProductCategory',
  components: {ModalComponent},
  computed: {
    type() {
      return [
        {
          label: 'Food',
          value: 'food'
        },
        {
          label: 'Beverage',
          value: 'beverage'
        },
        {
          label: 'Alcohol',
          value: 'alcohol'
        },
        {
          label: 'Dessert',
          value: 'dessert'
        }
      ]
    }
  },
  data() {
    return {
      isLoading: false,
      data: {
        label: '',
        parent: '',
        type: 'menu'
      },

    }
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true
      try {
        const payload = {
          label: this.data.label,
          parent: this.data.parent,
          type: this.data.type
        }
        await createCategoryMenu(payload)
        triggerSuccessNotification('Menu Category Added', () => {
          this.$emit('close')
        })
      } catch (e) {
        triggerErrorNotification(e)
      }
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped></style>
