<template>
  <div>
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="font-weight-bold mb-0">
          <button
              @click="handleClick"
              class="btn btn-lg">
            <i class="fa fa-arrow-left"></i>
          </button>
          Report
        </h4>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="col-12 mb-3">
        <div class="row">
          <div class="col-6">
            <h4>Today Sales</h4>
          </div>
          <div class="col-6">
            <DatePickerComponent :range="true" v-model="date"/>
          </div>
        </div>
      </div>
    </div>
    <SummaryReportComponent ref="summaryComp"/>
    <ReportChartDataComponent/>
    <div class="col-12 mb-3">
      <div
          @click="handleItem(item)"
          v-for="item in tabs"
          :key="item.label"
          :class="[item.isActive ? 'btn-primary' : 'btn-secondary']"
          class="btn btn-sm me-3">
        {{ item.label }}
      </div>
      <hr>
    </div>
    <SalesReportPerItemComponent v-if="activeTabs.id === 1 "/>
    <SalesPerOrderComponent v-if="activeTabs.id === 2 "/>
  </div>
</template>

<script>
import SummaryReportComponent from "@/views/dashboard/componets/SummaryReportComponent.vue";
import DatePickerComponent from "@/components/forms/DatePickerComponent.vue";
import moment from "moment";
import ReportChartDataComponent from "@/views/dashboard/reports/components/ReportChartDataComponent.vue";
import SalesReportPerItemComponent from "@/views/dashboard/reports/components/SalesReportPerItemComponent.vue";
import SalesPerOrderComponent from "@/views/dashboard/reports/components/SalesPerOrderComponent";

export default {
  name: 'ReportScreen',
  components: {
    SalesPerOrderComponent,
    SalesReportPerItemComponent,
    SummaryReportComponent,
    DatePickerComponent,
    ReportChartDataComponent
  },
  data() {
    return {
      date: null,
      startDate: moment().toString(),
      endDate: moment().add(1, 'days').toString(),
      tabs: [
        {
          id: 1,
          label: 'Per Item',
          isActive: true
        },
        {
          id: 2,
          label: 'Per Order',
          isActive: false
        }
      ],
      activeTabs: {
        id: 1,
        label: 'Per Item',
        isActive: true
      },
    }
  },
  computed: {
    query() {
      return this.$route.query
    }
  },
  methods: {
    handleClick() {
      this.$router.push({
        name: 'CashierDashboard'
      })
    },
    handleItem(item) {
      this.activeTabs = item
      this.tabs = this.tabs.map((v) => {
        v.isActive = v.id === item.id
        return v
      })
    }
  },
  watch: {
    date(val) {
      this.$router.push({
        name: this.$route.name,
        query: {
          startDate: moment(val[0]).format('YYYY-MM-DD'),
          endDate: moment(val[1]).format('YYYY-MM-DD')
        }
      })
    }
  },
  mounted() {

    if (this.$route.query) {
      this.startDate = moment(this.$route.query.startDate, 'YYYY-MM-DD').toString()
      this.endDate = moment(this.$route.query.endDate, 'YYYY-MM-DD').toString()
    }

  }
}
</script>
<style lang="scss" scoped></style>
