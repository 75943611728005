
import {Options, Vue} from 'vue-class-component';
import {LoginData} from "@/views/auth/types/LoginPageInterface";
import {triggerErrorNotification, triggerSuccessNotification} from "@/utils/helper";
import {doLogin} from "@/services/CashierService";

@Options({})
export default class LoginPage extends Vue {
  loginData: LoginData = {
    email: '',
    password: ''
  }

  isLoading = false

  async handleSubmit(): Promise<void> {
    this.isLoading = true
    try {
      const parameter = this.loginData
      const response = await doLogin(parameter)
      if (response && response.data) {
        const {access_token} = response.data.data
        localStorage.setItem('token', access_token)
        triggerSuccessNotification('Login Success', () => {
          this.$router.push({
            name: 'CashierDashboard'
          })
        })
      } else {
        throw new Error('Something went wrong')
      }
    } catch (e) {
      triggerErrorNotification(e)
    }
    this.isLoading = false
  }
}
