
import {Options, Vue} from 'vue-class-component';

@Options({})
export default class LogoutPage extends Vue {
  mounted() {
    localStorage.clear()
    this.$router.push('/')
  }
}
