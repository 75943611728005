<template>
  <router-link :to="{name: 'CashierOrderDetail', params: {id: data.uuid}}" class="col-6 mb-3 card-item">
    <div
        :class="renderClass(data.status)"
        class="card shadow border-0">
      <div class="row">
        <div class="col-12">
          <div class="card-body">
            Table : {{ data.table }} | Total Items: {{ totalItems }}
            <h5 class="m-0">Total Bill : <strong>{{ renderBill }}</strong> </h5>
            <small>service charge : {{ serviceCharge}}</small>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import {currencyFormater} from "@/utils/helper";

export default {
  name: 'OrderListItemComponent',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    totalItems() {
      return this.data.order_data.length
    },
    renderBill() {
      return currencyFormater(this.data.amount + this.data.service_amount)
    },
    serviceCharge() {
      return currencyFormater(this.data.service_amount)
    }
  },
  methods: {
    renderStatus(status) {
      if (status === 1) return 'unpaid'
      return 'paid'
    },
    renderClass(status) {
      if (status === 1) return `bg-danger text-light`
      return 'bg-success text-light'
    }
  }
}
</script>
<style lang="scss" scoped>
.card-item {
  color: black;
  text-decoration: none;
}

.card {
  overflow: hidden;
}
</style>
