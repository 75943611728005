<template>
  <div class="card shadow border-0 mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-3 pe-0">
          <div
              v-if="image"
              :style="{backgroundImage: `url(${image})`}"
              class="standard-thumbnail">
          </div>
        </div>
        <div class="col-8 pl-0">
          <strong>{{ data.name }}</strong>
          <div class="row px-0">
            <div class="col-12 mb-2 price-text">
              Price <br>{{ countPrice }}
            </div>
            <div
                v-if="!isView"
                class="col-12">
              <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                <button
                    @click="reduceWarning()"
                    type="button" class="btn btn-danger px-3">
                  -
                </button>
                <button type="button" class="btn btn-secondary px-3">
                  {{ countOrder }}
                </button>
                <button
                    @click="add()"
                    type="button" class="btn btn-success px-3">
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div
                v-else
                class="col px-0 text-end">
              Total Ordered: {{ countOrder }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import {currencyFormater, triggerErrorNotification} from "@/utils/helper";
import Swal from "sweetalert2";

export default {
  name: 'CashierOrderSummaryItemComponent',
  props: {
    data: {
      type: Object,
      required: true
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('resto', ['orderSummary', 'order']),
    image() {
      if(this.data.meta.image) {
        return this.data.meta.image.url
      }
      return require('@/assets/logo.png')
    },
    countOrder() {
      const data = this.orderSummary.filter((v) => {
        return v.uuid === this.data.uuid
      })
      return data.length
    },
    countPrice() {
      return currencyFormater(this.countOrder * this.data.price)
    }
  },
  methods: {
    ...mapMutations('resto', ['setOrderSummary']),
    add() {
      const data = this.orderSummary
      data.push(this.data)
      this.setOrderSummary(data)
    },
    async reduceWarning() {

      if(!this.order) {
        this.reduce()
      }else {
        Swal.fire({
          icon: 'warning',
          title: 'Delete Item',
          input: 'password',
          inputLabel: 'Input Manager Password',
          inputPlaceholder: '',
          showCancelButton: true
        }).then((res) => {

          const {isConfirmed, value} = res
          if (isConfirmed && value && value === process.env.VUE_APP_DEL_PASS) {
            this.reduce()
          }

          if (isConfirmed && value && value !== process.env.VUE_APP_DEL_PASS) {
            Swal.fire({
              icon: 'error',
              title: 'Incorrect Password'
            })
          }
        })
      }
    },
    reduce() {
      const data = this.orderSummary
      this.orderSummary.find((v, k) => {
        if (v.uuid === this.data.uuid) {
          data.splice(k, 1)
          this.setOrderSummary(data)
        }
        return v.uuid === this.data.uuid
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.card-body {
  padding: .5rem
}

.standard-thumbnail {
  padding-bottom: 100%;
  border-radius: 4px;
}

.price-text {
  line-height: .9rem;
  font-size: .9rem;
}
</style>
