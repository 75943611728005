<template>
  <main class="position-relative min-vh-100 bg-gray">
    <CashierNavbarComponent/>
    <div
        :class="['sidebar-hide']"
        class="container-fluid position-relative p-3 transition">
      <router-view></router-view>
    </div>
  </main>
</template>
<script>
import CashierNavbarComponent from "@/views/dashboard/componets/CashierNavbarComponent";
import {mapState} from "vuex";
import moment from "moment";
export default {
  name: 'CashierDashboardLayout',
  components: {CashierNavbarComponent},
  computed: {
    ...mapState(['userData']),
    currentDate() {
      return moment().format('DD/MMM/YYYY  HH:mm');
    }
  },
}
</script>
<style lang="scss" scoped></style>
