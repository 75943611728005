import {get, post} from "@/utils/http";

const API_URL = `${process.env.VUE_APP_API_URL}/api`

export const doLogin = (data: any) => {
  return post(`${API_URL}/v1/login`, data)
}

export const getMe = () => {
  return get(`${API_URL}/v1/me`, {})
}


export const getPosReport = (parameter: any) => {
  return get(`${API_URL}/v1/resto/report`, parameter)
}

export const getMenus = (data: any) => {
  const newUrl = `${API_URL}/v1/resto/menu-list`
  return get(newUrl, data)
}

export const createOrder = (data: any) => {
  const newUrl = `${API_URL}/v1/resto/order`
  return post(newUrl, data)
}

export const getOrderById = (id: string) => {
  const newUrl = `${API_URL}/v1/resto/order/${id}`
  return get(newUrl, {})
}

export const updateOrderById = (id: string, parameter: any) => {
  const newUrl = `${API_URL}/v1/resto/order/${id}`
  return post(newUrl, parameter)
}

export const payOrderById = (id: string, parameter: any) => {
  const newUrl = `${API_URL}/v1/resto/order/pay/${id}`
  return post(newUrl, parameter)
}

export const getOrders = (parameter: any) => {
  const newUrl = `${API_URL}/v1/resto/order`
  return get(newUrl, parameter)
}

export const createMenu = (parameter: any) => {
  const newUrl = `${API_URL}/v2/menu`
  return post(newUrl, parameter)
}

export const updateMenu = (parameter: any) => {
  const newUrl = `${API_URL}/v2/menu/${parameter.uuid}`
  return post(newUrl, parameter)
}

export const getMenuCategoryList = (parameter: any) => {
  return get(`${API_URL}/v1/product-category`, parameter)
}

export const createCategoryMenu = (parameter: any) => {
  return post(`${API_URL}/v1/product-category`, parameter)
}

