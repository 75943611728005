<template>
  <div class="col-6 mb-3">
    <div
        @click="addMenu"
        class="card shadow border-0">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <div
                v-if="imgBackground"
                :style="{backgroundImage: `url(${imgBackground})`}"
                class="standard-thumbnail">
            </div>
          </div>
          <div class="col-9 position-relative">
            <ButtonEditMenuComponent
              :data="data"
            />
            <strong>
              {{ data.name }}
            </strong>
            <br>
            {{ price(data.price) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import {currencyFormater} from "@/utils/helper";
import ButtonEditMenuComponent from "@/views/dashboard/create_order/components/ButtonEditMenuComponent";

export default {
  name: 'CashierMenuListItemComponent',
  components: {ButtonEditMenuComponent},
  props: {
    data: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isSummary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('resto', ['orderSummary']),
    imgBackground() {
      if(this.data.meta.image) {
        return this.data.meta.image.url
      }
      return require('@/assets/logo.png')
    }
  },
  methods: {
    ...mapMutations('resto', ['setOrderSummary']),
    price(data) {
      return currencyFormater(data)
    },
    addMenu() {
      const data = this.orderSummary
      const find = data.find((v) => {
        return v.uuid === this.data.uuid
      })

      if (!find) {
        data.push(this.data)
        this.setOrderSummary(data)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.card-body {
  padding: .75rem;
  font-size: .9rem;
}

.card {
  cursor: pointer;
}

.standard-thumbnail {
  border-radius: 4px;
  padding-bottom: 100%;
}
</style>
