import Swal from 'sweetalert2'
import moment from 'moment'

export const triggerNotification = (icon: string, title: string, message: string, cb: any = false) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Swal.fire({
    icon: icon,
    text: message,
    title: title
  }).then((result) => {
    if (result) {
      if (cb) cb()
    }
  })
}

export const numberFormater = (number: number) => {
  if (number) {
    const num = String(number)
    return (
      num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    )
  }
  return 0
}

export const currencyFormater = (num: number, type = false) => {
  if (num) {
    return `${type ? type : 'IDR'} ${numberFormatter(num)}`;
  }
  return 'IDR 0';
}

export const triggerErrorNotification = (msg: string, cb: any = false) => {
  triggerNotification('error', 'Error', handleMessage(msg), () => {
    if (cb) cb()
  })
}

export const triggerSuccessNotification = (msg: string, cb: any = false) => {
  triggerNotification('success', 'Success', msg, () => {
    if (cb) cb()
  })
}

export const dateFormatter = (str: any, time = false) => {
  if (str && time) return moment(str).format('DD MMM YYYY HH:mm')
  if (str) return moment(str).format('DD MMM YYYY')
  return '-'
}

export const numberFormatter = (number: number): string => {
  if (number) {
    const num = String(number)
    return (
      num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    )
  }
  return '0'
}

export const handleMessage = (e: any) => {
  let message = 'Something Went Wrong'
  if (e.message) message = e.message
  if (e.response && e.response.data && e.response.data.data && e.response.data.data.message) message = e.response.data.data.message
  
  return message
}
