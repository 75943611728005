<template>
  <div>
    <h5 class="font-weight-bold">
      Sales Child Category
    </h5>
    <hr>
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>
          Category
        </th>
        <th>
          Quantity
        </th>
        <th>
          Total
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          :key="val.category"
          v-for="val in sales">
        <td>
          {{ val.child_category }}
        </td>
        <td>
          {{ val.quantity }}
        </td>
        <td>
          {{ formatCurrency(val.total) }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {currencyFormater} from "@/utils/helper";

export default {
  name: 'SalesReportChildCategory',
  computed: {
    ...mapGetters('restoReport', ['products']),
    sales() {
      const data = []
      this.products.forEach((v) => {
        const findData = data.find((val) => {
          return val.child_category === v.child_category
        })

        if (!findData) {
          data.push(v)
        } else {

          const findCategory = data.find((val) => {
            return val.child_category === v.child_category
          })

          findCategory.quantity += Number(v.quantity)
          findCategory.total += Number(v.total)
        }
      })

      return data
    }
  },
  methods: {
    formatCurrency(number) {
      return currencyFormater(number)
    }
  }
}
</script>
<style lang="scss" scoped></style>
