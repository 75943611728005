<script>
import {mapState} from "vuex";

export default {
  name: 'OrderMixins',
  computed: {
    ...mapState('resto', ['orderSummary','discountAmount']),
    isPbl() {
      if(this.orderData) {
        if(this.orderData.tax_pbl_rate > 0) {
          return true
        }
      }
      return false
    },
    data() {
      if (this.orderSummary.length) {
        const data = []
        this.orderSummary.map((v) => {
          if (!data.length) {
            data.push(v)
          } else {
            const exist = data.find((val) => {
              return val.uuid === v.uuid
            })
            if (!exist) data.push(v)
          }
        })
        this.handleOrder()
        return data
      }
      this.handleOrder()
      return this.orderSummary
    },
    subTotal() {
      let total = 0

      if (this.orderSummary.length) {
        this.orderSummary.forEach((v) => {
          total += v.price
        })
      }

      if(this.discountAmount) total -= this.discountAmount

      return total
    },
    serviceCharge() {
      if (this.orderData) {
        const {service_amount} = this.orderData
        if(service_amount) {
          return service_amount
        }
      }

      return (this.subTotal * 0.9) * 0.05
    },
    tax() {
      if (this.orderData) {
        const {tax_rate} = this.orderData
        if (tax_rate) {
          return (this.subTotal + this.serviceCharge) * (tax_rate / 100)
        }
      }
      return 0
    },
    taxPbl() {
      if(this.orderData) {
        const {tax_pbl_rate} = this.orderData
        if (tax_pbl_rate) {
          return (this.subTotal) * (+tax_pbl_rate / 100)
        }
      }
      return 0
    },
    total() {
      return this.subTotal + this.taxPbl
    }
  },
  methods: {
    handleOrder() {
      const orders = []
      this.orderSummary.forEach((v) => {
        const exist = orders.find((val) => {
          return val.product_id === v.uuid
        })

        if (!exist) {
          orders.push({
            product_id: v.uuid,
            qty: 1,
            total: v.price,
            product_name: v.name
          })
        } else {
          exist.qty += 1
          exist.total += v.price
        }
      })
      this.orders = orders
    },
  }
}
</script>
