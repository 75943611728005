<template>
  <div>
    <button
        @click="isShow = !isShow"
        class="btn btn-sm btn-primary">
      <span class="fa fa-plus-circle"></span> Add Custom Menu
    </button>
    <ModalAddCustomMenuButtonComponent
      v-if="isShow"
      @close="handleClose"
    />
  </div>
</template>
<script>
import ModalAddCustomMenuButtonComponent
  from "@/views/dashboard/create_order/components/ModalAddCustomMenuButtonComponent";
export default {
  name: 'AddCustomMenuButtonComponent',
  components: {ModalAddCustomMenuButtonComponent},
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    handleClose() {
      this.isShow = false
      this.$emit('close-add-menu')
    }
  }
}
</script>
<style lang="scss" scoped></style>
