export default {
  state: {
    orderSummary: [],
    isPaid: false,
    totalOrderAmount: 0,
    discountAmount: 0,
    order: null,
    categoryMenu: []
  },
  getters: {
    finalOrderAmount(state: any): number {
      return state.totalOrderAmount - state.discountAmount
    }
  },
  mutations: {
    setOrder(state: any, value: any) {
      state.order = value
    },
    setIsPaid(state: any, value: any): void {
      state.isPaid = value
    },
    setOrderSummary(state: any, value: any): void {
      state.orderSummary = value
    },
    setTotalOrderAmount(state: any, value: any): void {
      state.totalOrderAmount = value
    },
    setDiscountAmount(state: any, value: any): void {
      state.discountAmount = value
    },
    setCategoryMenu(state: any, value: any): void {
      state.categoryMenu = value
    }
  },
}
