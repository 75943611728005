<template>
    <div class="row">
        <div class="col-6">
            <h4 class="font-weight-bold">
                Order Lists
            </h4>
        </div>
        <div class="col-6 text-end">
            <router-link :to="{name: 'CashierCreateOrder'}" class="btn btn-primary shadow">
                <i class="fa fa-plus-circle"></i> Create Order
            </router-link>
        </div>
        <div class="col-12 mb-3">
            <hr>
        </div>
        <OrderListComponent/>
    </div>
</template>
<script>
import OrderListComponent from "./OrderListComponent";

export default {
    name: 'CashierDashboarOrderManagement',
    components: {OrderListComponent},
}
</script>
<style lang="scss" scoped></style>
