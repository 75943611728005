<template>
  <div class="col-12 mb-3">
    <div class="row">
      <div class="col-6">
        <ReportItemSalesComponent />
      </div>
      <div class="col-6">
        <SalesReportCategoryComponent />
        <SalesReportChildCategory />
      </div>
    </div>
  </div>
</template>
<script>
import ReportItemSalesComponent from "@/views/dashboard/reports/components/ReportItemSalesComponent";
import SalesReportChildCategory from "@/views/dashboard/reports/components/SalesReportChildCategory";
import SalesReportCategoryComponent from "@/views/dashboard/reports/components/SalesReportCategoryComponent";
export default {
  name: 'SalesReportPerItemComponent',
  components: {SalesReportCategoryComponent, SalesReportChildCategory, ReportItemSalesComponent}
}
</script>
<style lang="scss" scoped></style>
