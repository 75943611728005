<template>
  <tr>
    <td>
      {{ data.name }}
    </td>
    <td>
      {{ data.quantity }}
    </td>
    <td class="text-end">
      {{ total }}
    </td>
  </tr>
</template>
<script>
import {currencyFormater} from "@/utils/helper";

export default {
  name: 'ReportItemProductComponent',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    meta() {
      const {meta} = this.data
      return meta
    },
    image() {
      const {image} = this.meta
      return image && image.url ? image.url : ''
    },
    total() {
      return currencyFormater(this.data.total)
    }
  }
}
</script>
<style lang="scss" scoped></style>
