<template>
  <tr>
    <td>
      <div class="row">
        <div class="col-10">
          Order Id: <strong>{{ item.uuid }}</strong> table : {{ item.table }}
        </div>
        <div class="col-2 text-end">
          <button
              @click="isShow = !isShow"
              class="btn btn-primary btn-sm">
            <i class="fa fa-plus-circle"></i>
          </button>
        </div>
      </div>
      <OrderListItemsComponent
          v-if="isShow"
          :data="item"/>
    </td>
    <td>
      {{ formatDate(item.created_at) }}
    </td>
    <td>
      {{ item.status === 1 ? 'Unpaid' : 'Paid' }}
    </td>
    <td>
      {{ formatCurrency(item.amount - item.discount_amount) }}
    </td>
  </tr>

</template>
<script>
import OrderListItemsComponent from "@/views/dashboard/reports/components/OrderListItemsComponent";
import {currencyFormater, dateFormatter} from "@/utils/helper";

export default {
  name: 'OrderListItemRowComponent',
  components: {OrderListItemsComponent},
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    formatDate(date) {
      return dateFormatter(date, true)
    },
    formatCurrency(number) {
      return currencyFormater(number)
    }
  }
}
</script>
<style lang="scss" scoped></style>
