<template>
    <div class="row mb-3">
        <div class="col-12">
            <h4 class="font-weight-bold mb-0">
                <button
                    @click="handleClick"
                    class="btn btn-lg">
                    <i class="fa fa-arrow-left"></i>
                </button>
                {{ renderTitle }}
            </h4>
        </div>
        <div class="col-12">
            <hr>
        </div>
        <div class="col-12">
            <CashierMenuListComponent/>
        </div>
    </div>
</template>
<script>
import CashierMenuListComponent from "./components/CashierMenuListComponent";

export default {
    name: 'CreateOrderPage',
    components: {CashierMenuListComponent},
    methods: {
        handleClick() {
            this.$router.push({
                name: 'CashierDashboard'
            })
        }
    },
    computed: {
        renderTitle() {
            if(this.$route.params.id) return `Edit Order`
            return `Create New Order`
        }
    }
}
</script>
<style lang="scss" scoped></style>
