<template>
  <div>
    <h5 class="font-weight-bold">
      Item Sold
    </h5>
    <hr>
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>
          Item
        </th>
        <th>
          Quantity
        </th>
        <th>
          Total
        </th>
      </tr>
      </thead>
      <tbody>
      <ReportItemProductComponent
          v-for="val in products"
          :data="val"
          :key="val.uuid"
      />
      </tbody>
      <tfoot>
      <tr>
        <td>
          <strong>Total</strong>
        </td>
        <td>
          <strong>{{ itemSold }}</strong>
        </td>
        <td class="text-end">
          <strong>{{ amountSold }}</strong>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <strong>Service Charge</strong>
        </td>
        <td class="text-end">
          <strong>{{ serviceCharge }}</strong>
        </td>
      </tr>
      <tr v-if="taxPbl">
        <td colspan="2">
          <strong>Tax PBL</strong>
        </td>
        <td class="text-end">
          <strong>{{ taxPbl }}</strong>
        </td>
      </tr>
      <tr class="text-danger">
        <td colspan="2">
          <strong>Discount</strong>
        </td>
        <td class="text-end">
          <strong>{{ discountCurrency }}</strong>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <strong>
            Sub Total
          </strong>
        </td>
        <td class="text-end">
          <strong>{{ subTotal }}</strong>
        </td>
      </tr>
      </tfoot>
    </table>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import ReportItemProductComponent from "./ReportItemProductComponent";
import {currencyFormater} from "@/utils/helper";

export default {
  name: 'ReportItemSalesComponent',
  components: {ReportItemProductComponent},
  computed: {
    ...mapGetters('restoReport', ['products', 'totalDiscount', 'totalServiceCharge', 'totalPbl']),
    itemSold() {
      let total = 0
      this.products.map((v) => {
        total += v.quantity
      })
      return total
    },
    totalAmount() {
      let total = 0
      this.products.map((v) => {
        total += v.total
      })
      return total
    },
    taxPbl() {
      return currencyFormater(this.totalPbl)
    },
    serviceCharge() {
      return currencyFormater(this.totalServiceCharge)
    },
    amountSold() {
      return currencyFormater(this.totalAmount)
    },
    discountCurrency() {
      return currencyFormater(this.totalDiscount)
    },
    subTotal() {
      return currencyFormater(this.totalAmount - this.totalDiscount + this.totalServiceCharge + this.totalPbl)
    }
  },
}
</script>
<style lang="scss" scoped>
td {
  padding: .5rem
}
</style>
