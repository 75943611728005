<template>
  <div
      class="card-body">
    <div class="row">
      <div class="col-6 d-flex align-items-center">
        <h6 class="font-weight-bold text-light">
          Current Order
        </h6>
      </div>
      <div class="col-6">
        <input type="number" v-model="tableNumber"
               placeholder="Table Number"
               class="form-control form-control-sm" required>
      </div>
    </div>
    <hr>
    <CashierOrderSummaryItemComponent v-for="(val,key) in data"
                                      :is-view="orderData && orderData.status === 2"
                                      :data="val" :key="key"/>
    <CashierTotalOrderComponent :order-data="orderData"/>
    <div
        v-if="isShowButtonAction"
        class="text-end">
      <PrintBillButtonComponent
          v-if="!isLoading && $route.params.id"
      />
      <button
          v-if="!isLoading && $route.params.id"
          @click="handlePrintMenu"
          class="btn btn-info btn-sm me-2 ms-2">
        Print Menu
      </button>
      <button
          v-if="!isLoading"
          @click="addOrder"
          class="btn shadow btn-warning btn-sm"> {{ renderText }}
      </button>
      <button
          v-if="isLoading"
          class="btn btn-warning btn-sm">
        <LoadingComponent class="text-sm"/>
      </button>
      <router-link
          :to="{
                name:'CashierPay',
                params: {
                    id: $route.params.id
                }
            }"
          v-if="isShowPayButton"
          class="btn shadow ms-2 btn-danger btn-sm">
        <i class="fa fa-money-bill"></i> Pay
      </router-link>
    </div>
    <div v-if="$route.params.id && orderData && orderData.status === 1" class="mt-3">
      <small class="text-white">
        *) always click update order whenever update the menus
      </small>
    </div>
    <div v-if="isShowPaymentSummary">
      <div class="alert alert-success">
        Order has been paid
      </div>
    </div>
    <CashierOrderPaymentInformationComponent v-if="isShowPaymentSummary"
                                             :orders="orders"
                                             :order-data="orderData"/>
  </div>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import CashierOrderSummaryItemComponent from "./CashierOrderSummaryItemComponent";
import CashierTotalOrderComponent from "./CashierTotalOrderComponent";
import {createOrder, getOrderById, updateOrderById} from "@/services/CashierService";
import CashierOrderPaymentInformationComponent from "../../orders/components/CashierOrderPaymentInformationComponent";
import {triggerErrorNotification, triggerSuccessNotification} from "@/utils/helper";
import LoadingComponent from "@/components/LoadingComponent";
import OrderMixins from "@/mixins/OrderMixins";
import PrintBillButtonComponent from "@/views/dashboard/orders/components/PrintBillButtonComponent";

export default {
  name: 'CashierOrderSummaryComponent',
  components: {
    PrintBillButtonComponent,
    LoadingComponent,
    CashierOrderPaymentInformationComponent,
    CashierTotalOrderComponent, CashierOrderSummaryItemComponent
  },
  mixins: [OrderMixins],
  data() {
    return {
      isLoading: false,
      tableNumber: '',
      orders: [],
      orderData: null,
      isChange: false,
      orderCount: 0
    }
  },
  computed: {
    ...mapState('resto', ['orderSummary']),
    isShowPaymentSummary() {
      return this.orderData && this.orderData.status === 2
    },
    isShowPayButton() {
      return this.$route.params.id && this.orderData.status === 1
    },
    renderText() {
      if (this.$route.params.id) return 'Update Order'
      return 'Save Order'
    },
    isShowButtonAction() {
      if (this.orderData) {
        return this.orderData.status === 1
      }

      return this.orderSummary.length
    }
  },
  methods: {
    ...mapMutations('resto', ['setOrderSummary', 'setOrder']),
    async handlePrintMenu() {
      if (!this.isLoading) {
        this.isLoading = true
        try {
          const url = `${process.env.VUE_APP_API_URL}/print/menu/${this.$route.params.id}`
          window.open(
              url,
              '_blank'
          )
        } catch (e) {
          triggerErrorNotification(e)
        }
        this.isLoading = false
      }
    },
    async addOrder() {
      this.isLoading = true
      try {

        if (!this.tableNumber) throw new Error('Table Number is empty')
        let total = 0
        this.orders.forEach((v) => {
          total += v.total
        })
        const parameter = {
          table: this.tableNumber,
          orders: this.orders,
          total
        }
        let res = null
        if (this.$route.params.id) {
          res = await updateOrderById(this.$route.params.id, parameter)
          await this.getData()
          this.isChange = false
        } else {
          res = await createOrder(parameter)
          this.isChange = false
          triggerSuccessNotification('Order Saved', () => {
            this.$router.push({
              name: 'CashierOrderDetail',
              params: {id: res.data.data.id}
            })
          })
        }
      } catch (e) {
        triggerErrorNotification(e)
      }
      this.isLoading = false
    },
    async getData() {
      this.isLoading = true
      try {
        const response = await getOrderById(this.$route.params.id)
        this.tableNumber = response.data.data.table_number
        this.setOrder(response.data.data)
        this.setOrderSummary(response.data.data.order_summary)
        this.orderData = response.data.data
        const {order_summary} = this.orderData
        this.orderSummary = order_summary
      } catch (e) {
        triggerErrorNotification(e)
      }
      this.isLoading = false
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  beforeMount() {
    this.setOrderSummary([])
  },
  beforeUnmount() {
    this.setOrderSummary([])
  }
}
</script>
<style lang="scss" scoped></style>
