<template>
  <button
      class="btn btn-warning shadow btn-sm" @click="doPrint">
    Print Bill
  </button>
</template>
<script>
export default {
  name: 'PrintBillButtonComponent',
  methods: {
    async doPrint() {
      const url = `${process.env.VUE_APP_API_URL}/print/bill/${this.$route.params.id}`
      window.open(
          url,
          '_blank'
      )
    }

  }
}
</script>
<style lang="scss" scoped></style>
