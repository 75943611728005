<template>
  <div class="row mb-3">
    <div class="col-12">
      <h4 class="font-weight-bold mb-0">
        <button
            @click="$router.back()"
            class="btn btn-lg">
          <i class="fa fa-arrow-left"></i>
        </button>
        Payment Checkout
      </h4>
    </div>
    <div class="col-12">
      <hr>
    </div>
    <div class="col-12" v-if="isLoading">
      <LoadingComponent/>
    </div>
    <div class="col-4 mb-3" v-else>
      <div class="mb-3">
        <div class="card-body">
          <h5 class="font-weight-bold">
            Order Detail
          </h5>
          <hr>

          <p>
            Table Number: {{ tableNumber }}
          </p>
          <CashierOrderSummaryItemComponent v-for="(val,key) in data"
                                            :is-view="true"
                                            :data="val" :key="key"/>
        </div>
      </div>
      <div
          v-if="orderData.status === 1"
          class="text-end">
        <router-link class="btn shadow btn-primary btn-sm" :to="{
                    name: 'CashierOrderDetail',
                    params: {
                        id: $route.params.id
                    }
                }">
          Edit Order
        </router-link>
      </div>
    </div>
    <div v-if="!isLoading" class="col-4 mb-3">
      <CashierTotalOrderComponent :order-data="orderData"/>
      <PaymentInformationComponent :order-data="orderData"/>
    </div>
    <div class="col-4 mb-3">
      <CashierOrderPaymentInformationComponent v-if="!isLoading"
                                               :orders="orders"
                                               :order-data="orderData"/>
    </div>
  </div>
</template>
<script>
import OrderMixins from "@/mixins/OrderMixins";
import {getOrderById} from "@/services/CashierService";
import {mapMutations} from "vuex";
import LoadingComponent from "@/components/LoadingComponent";
import CashierTotalOrderComponent from "../create_order/components/CashierTotalOrderComponent";
import CashierOrderSummaryItemComponent from "../create_order/components/CashierOrderSummaryItemComponent";
import PaymentInformationComponent from "./components/PaymentInformationComponent";
import CashierOrderPaymentInformationComponent from "./components/CashierOrderPaymentInformationComponent";
import {triggerErrorNotification} from "@/utils/helper";

export default {
  name: 'CashierPayOrderPage',
  components: {
    CashierOrderPaymentInformationComponent,
    PaymentInformationComponent,
    CashierOrderSummaryItemComponent, CashierTotalOrderComponent, LoadingComponent
  },
  mixins: [OrderMixins],
  data() {
    return {
      isLoading: true,
      tableNumber: null,
      orderData: null,
      orders: []
    }
  },
  methods: {
    ...mapMutations('resto', ['setOrderSummary','setTotalOrderAmount']),
    async getData() {
      this.isLoading = true
      try {
        const response = await getOrderById(this.$route.params.id)
        this.tableNumber = response.data.data.table_number
        this.setOrderSummary(response.data.data.order_summary)
        this.setTotalOrderAmount(response.data.data.total)
        this.orderData = response.data.data
        this.handleOrder()
      } catch (e) {
        triggerErrorNotification(e)
      }
      this.isLoading = false
    },
  },
  mounted() {
    this.getData()
  }
}
</script>
<style lang="scss" scoped></style>
