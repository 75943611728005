<template>
    <div class="col-3">
        <div
            :class="[cardClass]"
            class="card shadow">
            <div class="card-body text-light">
                <h4>
                    {{ content }}
                </h4>
                <p class="mb-0">
                    {{ title }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BoxCardComponent',
    props: {
        cardClass: {
            type: String,
            default: ''
        },
        content: {
            type: [String, Number],
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    }
}
</script>
<style lang="scss" scoped></style>
