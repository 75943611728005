import axios from "axios";

const esc = encodeURIComponent
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  Authorization: '',
  'App-id': process.env.VUE_APP_APP_ID,
  'App-Secret': process.env.VUE_APP_APP_SECRET
}

export const get = (url: string, data: any) => {
  let query = ''
  
  if (data) {
    query = Object.keys(data)
      .map(k => `${esc(k)}=${esc(data[k])}`)
      .join('&')
  }
  
  url = `${url}?${query}`
  
  const token = localStorage.getItem('token')
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  
  return axios({
    method: 'get',
    url,
    headers
  })
}

export const post = (url: string, data: any) => {
  
  const token = localStorage.getItem('token')
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  
  if (data.file) {
    const formData = new FormData()
    headers['Content-Type'] = 'multipart/form-data'
    Object.keys(data).forEach((v) => {
      formData.append(v, data[v])
    })
    data = formData
  } else {
    headers['Content-Type'] = 'application/json'
    data = JSON.stringify(data)
  }
  return axios({
    method: 'post',
    url,
    headers,
    data
  })
}
