<template>
  <div v-if="!isLoading">
    <div class="card shadow border-0 mb-3">
      <div class="card-body">
        <p>
          Payment Information
        </p>
        <div class="form-group">
          <label for="">
            Payment Type
          </label>
          <select v-model="payment.type" class="form-control form-control-sm">
            <option value="1">Cash</option>
            <option value="2">Debit</option>
            <option value="3">Credit</option>
            <option value="4">Other</option>
          </select>
        </div>
        <div
            v-if="isCard"
            class="row"
        >
          <div class="col-6">
            <div class="form-group">
              <label for="">
                Card Number
              </label>
              <input type="number" class="form-control form-control-sm" v-model="payment.card_name">
            </div>
            <div class="form-group">
              <label for="">
                Card Expiry Date
              </label>
              <input type="text" class="form-control form-control-sm"
                     v-model="payment.card_expiry_date" placeholder="MM/YY">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="">
                Card Type
              </label>
              <select
                  :disabled="orderData.status === 2"
                  v-model="payment.card_type" class="form-control form-control-sm">
                <option value="visa">Visa</option>
                <option value="master">Master</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="">Notes</label>
          <textarea
              :disabled="orderData.status === 2"
              v-model="notes" class="form-control form-control-sm" rows="3"></textarea>
        </div>
      </div>
    </div>
    <div
        class="card shadow border-0 mb-3">
      <div class="card-body">
        <div class="form-group mb-3">
          <label for="">Add Discount</label>
          <select
              :disabled="isDiscountDisbled"
              v-model="payment.discount_type" class="form-control">
            <option value="">-- Select Discount Type</option>
            <option value="complimentary">Complimentary</option>
            <option value="food">Food</option>
            <option value="beverage">Beverage</option>
            <option value="dessert">Dessert</option>
            <option value="alcohol">Alcohol</option>
          </select>
        </div>
        <div
            v-if="payment.discount_type && payment.discount_type !== 'complimentary' "
            class="form-group mb-3">
          <label for="">
            Amount
          </label>
          <input
              :disabled="isDiscountDisbled"
              type="number" class="form-control"
              :placeholder="discount_amount_placeholder"
              v-model="discount_amount" required>
        </div>
        <div
            v-if="payment.discount_type && payment.discount_type !== 'complimentary' "
            class="form-group mb-3">
          <label for="">
            Percentage
          </label>
          <input
              :disabled="isDiscountDisbled"
              type="number" class="form-control"
              :placeholder="discount_percentage_placeholder"
              v-model="discount_percentage" required>
        </div>
      </div>
    </div>
    <div
        v-if="orderData.status === 1"
        class="form-group text-end">
      <div
          @click="doPay"
          class="btn shadow btn-primary btn-sm">
        Pay
      </div>
    </div>
  </div>
  <div v-else>
    <div class="card mb-3">
      <div class="card-body">
        <LoadingComponent/>
      </div>
    </div>
  </div>
</template>
<script>
import {payOrderById} from "@/services/CashierService";
import LoadingComponent from "@/components/LoadingComponent";
import {triggerErrorNotification, triggerSuccessNotification} from "@/utils/helper";
import {mapMutations} from "vuex";

export default {
  name: 'PaymentInformationComponent',
  components: {LoadingComponent},
  props: {
    orderData: {
      type: Object,
      required: true
    }
  },
  computed: {
    isCard() {
      return String(this.payment.type) === String(2) || String(this.payment.type) === String(3)
    },
    isDiscountDisbled() {
      if (this.orderData) {
        return this.orderData.status !== 1
      }
      return false
    }

  },
  data() {
    return {
      isLoading: false,
      payment: {
        type: '1',
        card_name: '',
        card_type: '',
        card_expiry_date: '',
        card_number: '',
        card_cvv_number: '',
        discount_type: '',
        discount_amount: '',
        discount_percentage: ''
      },
      discount_amount: '',
      discount_percentage: '',
      discount_amount_placeholder: 0,
      discount_percentage_placeholder: 0,
      notes: ''
    }
  },
  watch: {
    payment: {
      handler: function (val) {
        if(this.orderData.status !== 1) return
        this.setDiscountAmount(0)
        if (val.discount_type === 'complimentary') {
          this.resetAmountDiscount()
          this.setDiscountAmount(this.orderData.total)
          this.discount_amount = this.orderData.total
        }
      },
      deep: true
    },
    discount_amount(val) {

      if (val > this.orderData.total) {
        triggerErrorNotification({message: `Discount Amount should not exceed total order`}, () => {
          this.resetAmountDiscount()
        })

        return
      }

      if (val > 0) {
        this.setDiscountAmount(val)
        this.discount_percentage_placeholder = ((val / this.orderData.total) * 100).toFixed(2)
        this.discount_percentage = ''
      }
    },
    discount_percentage(val) {
      if (val > 100) {
        triggerErrorNotification({message: `Discount Amount should not exceed total order`}, () => {
          this.resetAmountDiscount()
        })

        return
      }
      if (val > 0) {
        this.discount_amount_placeholder = (val / 100) * this.orderData.total
        this.discount_amount = ''
        this.setDiscountAmount(this.discount_amount_placeholder)
      }
    }
  },
  methods: {
    resetAmountDiscount() {
      this.discount_amount = ''
      this.discount_percentage = ''
      this.discount_amount_placeholder = 0
      this.discount_percentage_placeholder = 0
    },
    ...mapMutations('resto', ['setDiscountAmount']),
    async doPay() {
      this.isLoading = true
      try {
        this.payment.discount_amount = this.discount_amount ? this.discount_amount : this.discount_amount_placeholder
        this.payment.discount_percentage = this.discount_percentage ? this.discount_percentage : this.discount_percentage_placeholder
        const {type} = this.payment
        if (!type) throw new Error('Payment Type is required')
        const parameter = {
          ...this.payment,
          notes: this.notes,
        }

        await payOrderById(this.$route.params.id, parameter)
        triggerSuccessNotification('Payment success', () => {
          window.location.reload()
        })
      } catch (e) {
        triggerErrorNotification(e)
      }

      this.isLoading = false
    }
  },

  mounted() {
    if (this.orderData) {
      const {invoice} = this.orderData
      if (invoice) {
        const {payment} = invoice
        if (payment) {
          this.payment = {
            type: payment.type,
            card_name: payment.card_name,
            card_number: payment.card_number,
            card_expiry_date: payment.card_expiry_date,
            card_cvv_number: payment.card_cvv_number,
            discount_amount: payment.discount_amount,
            discount_type: payment.discount_type,
            discount_percentage: payment.discount_percentage
          }

          this.discount_amount = payment.discount_amount
          this.setDiscountAmount(payment.discount_amount)
          this.notes = invoice.notes
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
