<template>
  <table class="table mt-3">
    <thead>
    <tr>
      <th>
        Item
      </th>
      <th>
        Qty
      </th>
      <th class="text-end">
        Total
      </th>
    </tr>
    </thead>
    <tbody>
    <tr
        :key="item.uuid"
        v-for="item in data.order_data">
      <td>
        {{ item.product.name }}
      </td>
      <td>
        {{ item.quantity }}
      </td>
      <td class="text-end">{{ formatCurrency(item.total) }}</td>
    </tr>
    <tr v-if="discount">
      <td colspan="2">
        <strong>Discount</strong>
      </td>
      <td class="text-end text-danger">
        {{ discount }}
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>
import {currencyFormater} from "@/utils/helper";

export default {
  name: 'OrderListItemsComponent',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    discount() {
      const {invoice} = this.data
      if (invoice) {
        const {payment} = invoice
        if (payment && payment.discount_amount > 0) return currencyFormater(payment.discount_amount)
      }
      return false
    }
  },
  methods: {
    formatCurrency(number) {
      return currencyFormater(number)
    }
  }
}
</script>
<style lang="scss" scoped></style>
