<template>
    <div class="col-12 mb-5">
        <div class="row">
            <OrderListItemComponent
                v-for="item in unpaidData"
                :key="item.uuid"
                :data="item"
            />
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import OrderListItemComponent from "./OrderListItemComponent";

export default {
    name: 'OrderListComponent',
    components: {OrderListItemComponent},
    computed: {
        ...mapGetters('restoReport', ['unpaidData'])
    }
}
</script>
<style lang="scss" scoped></style>
