import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import LoginPage from "@/views/auth/LoginPage.vue";
import CashierDashboardLayout from "@/views/dashboard/CashierDashboardLayout.vue";
import store from "@/store";
import {getMe} from "@/services/CashierService";
import CashierDashboardPage from "@/views/dashboard/CashierDashboardPage.vue"
import CreateOrderPage from "@/views/dashboard/create_order/CreateOrderPage.vue"
import OrdersListPage from "@/views/dashboard/orders/OrdersListPage.vue"
import CashierPayOrderPage from "@/views/dashboard/orders/CashierPayOrderPage.vue"
import {RouterView} from "vue-router";
import ReportScreen from "@/views/dashboard/reports/ReportScreen.vue"
import LogoutPage from "@/views/auth/LogoutPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutPage
  },
  {
    path: '/dashboard',
    component: CashierDashboardLayout,
    children: [
      {
        path: '',
        name: 'CashierDashboard',
        component: CashierDashboardPage,
        meta: {
          isAuth: true
        }
      },
      {
        path: 'create-order',
        name: 'CashierCreateOrder',
        component: CreateOrderPage,
        meta: {
          isAuth: true
        }
      },
      {
        path: 'orders',
        component: RouterView,
        children: [
          {
            path: '',
            name: 'CashierOrderList',
            component: OrdersListPage,
            meta: {
              isAuth: true
            }
          },
          {
            path: ':id',
            component: RouterView,
            children: [
              {
                path: '',
                name: 'CashierOrderDetail',
                component: CreateOrderPage,
                meta: {
                  isAuth: true
                },
              },
              {
                path: 'pay',
                name: 'CashierPay',
                component: CashierPayOrderPage,
                meta: {
                  isAuth: true
                },
              }
            ]
          }
        ]
      },
      {
        path: 'report',
        component: RouterView,
        meta: {
          isAuth: true
        },
        children: [
          {
            path: "",
            name: 'ReportList',
            component: ReportScreen
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const {meta} = to
  if (meta) {
    if (meta.isAuth) {
      const token = localStorage.getItem('token')
      if (!token) next({name: 'Login'})
      const {userData} = store.state
      if (!userData) {
        const response = await getMe()
        store.state.userData = response.data.data
      }
    }
  }
  next()
})


export default router
