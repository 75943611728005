<template>
  <div class="row mb-3">
    <div class="col-6">
      <h4 class="font-weight-bold mb-0">
        <button
            @click="handleClick"
            class="btn btn-lg">
          <i class="fa fa-arrow-left"></i>
        </button>
        Order Lists
      </h4>
    </div>
    <div class="col-6">
      <div class="row d-flex justify-content-end">
        <div class="col-3">
        </div>
        <div class="col-3">
          <select v-model="status" class="form-control">
            <option value="">All</option>
            <option value="2">Paid</option>
            <option value="1">Unpaid</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-12">
      <hr>
    </div>
    <div class="col-12">
      <LoadingComponent v-if="isLoading"/>
      <div
          v-else
          class="row">
        <OrderListItemComponent v-for="item in orders" :key="item.uuid" :data="item"/>
      </div>
      <div v-if="!orders.length && !isLoading">
        <div class="row">
          <div class="col-6">
            No Order for date : {{ dateFormatter(date) }}
          </div>
          <div class="col-6">
            <DatePickerComponent :range="false"
                                 v-model="date"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getOrders} from "@/services/CashierService";
import moment from "moment";
import OrderListItemComponent from "../componets/OrderListItemComponent";
import {dateFormatter, triggerErrorNotification} from "@/utils/helper";
import LoadingComponent from "@/components/LoadingComponent";
import DatePickerComponent from "@/components/forms/DatePickerComponent.vue";

export default {
  name: 'OrdersListPage',
  components: {
    DatePickerComponent,
    LoadingComponent,
    OrderListItemComponent,
  },
  data() {
    return {
      isLoading: false,
      status: '',
      date: moment(),
      options: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
      },
      orders: []
    }
  },
  computed: {
    query() {
      return this.$route.query
    }
  },
  watch: {
    date(val) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.query,
          ...{date: val}
        }
      })
    },
    status(val) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.query,
          ...{status: val}
        }
      })
    },
    '$route.query'() {
      this.getData()
    }
  },
  methods: {
    dateFormatter,
    handleClick() {
      this.$router.push({
        name: 'CashierDashboard'
      })
    },
    async getData() {
      this.isLoading = true
      try {
        const parameter = {
          date: moment(this.date).format('DD/MM/YYYY'),
          status: this.status
        }

        const response = await getOrders(parameter)
        this.orders = response.data.data
      } catch (e) {
        triggerErrorNotification(e)
      }

      this.isLoading = false
    }
  },
  mounted() {
    this.isLoading = true
    if (this.query) {
      const {date, status} = this.query
      if (date) this.date = date
      if (status) this.status = status
    }

    setTimeout(() => {
      this.getData()
    }, 500)
  }
}
</script>
<style lang="scss" scoped></style>
