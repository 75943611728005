<template>
  <div class="container-fluid mb-3 bg-blue px-0">
    <div class="row">
      <div class="col-12">
        <div class="card bg-blue shadow py-2">
          <div class="btn-group" role="group" aria-label="Basic example text-light">
            <router-link
                v-for="item in menus"
                :key="item.label"
                :to="{name: item.routeName}" type="button" class="btn text-light">
              <i class="fa" :class="[item.icon]"></i>
            </router-link>
            <button class="btn text-light">
              Hello, {{ userData.username }} ~ <small> {{ currentDate }} </small>
            </button>
            <button
                @click="doRefresh"
                class="btn text-light">
              <i class="fa fa-refresh"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import moment from 'moment/src/moment'

export default {
  name: 'CashierNavbarComponent',
  computed: {
    ...mapState(['userData']),
    currentDate() {
      return moment().format('DD/MMM/YYYY  HH:mm');
    },
    menus() {
      return [
        {
          label: 'Home',
          icon: 'fa-home',
          routeName: 'CashierDashboard'
        },
        {
          label: 'Orders',
          icon: 'fa-list',
          routeName: 'CashierOrderList'
        },
        {
          label: 'Report',
          icon: 'fa-chart-simple',
          routeName: 'ReportList'
        },
        {
          label: 'Logout',
          icon: 'fa-sign-out',
          routeName: 'Logout'
        }
      ]
    }
  },
  methods: {
    doRefresh() {
      window.location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.sidebar-show {
  left: 280px
}

.sidebar-hide {
  left: 0px;
}
</style>
