<template>
    <div class="d-flex align-items-center justify-content-center">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LoadingComponent'
}
</script>
<style lang="scss" scoped></style>
