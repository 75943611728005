<template>
  <div
      v-if="orderSummary.length"
      class="card mb-3 shadow border-0"
  >
    <div class="card-body">
      <div class="row">
        <div class="col-6 mb-1">
          Sub Total
        </div>
        <div class="col-6 mb-1 text-end">
          {{ currencyFormater(subTotal) }}
        </div>
        <div class="col-12">
          <hr>
        </div>
        <div
            v-if="isPbl"
            class="col-6 mb-1">
          Pajak PB1 ({{ this.orderData.tax_pbl_rate }} %)
        </div>
        <div
            v-if="isPbl"
            class="col-6 mb-1 text-end">
          {{ currencyFormater(taxPbl) }}
        </div>
        <div class="col-6 mb-1">
          <strong>
            Total
          </strong>
        </div>
        <div class="col-6 mb-1 text-end">
          {{ currencyFormater(total) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import OrderMixins from "@/mixins/OrderMixins";
import {currencyFormater} from "@/utils/helper";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'CashierTotalOrderComponent',
  mixins: [OrderMixins],
  props: {
    orderData: {
      type: Object,
      default: null
    }
  },
  methods: {
    currencyFormater(data) {
      return currencyFormater(data)
    }
  }
}
</script>
<style lang="scss" scoped>
.card-body {
  padding: .75rem
}
</style>
