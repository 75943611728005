import {currencyFormater} from "@/utils/helper";

export default {
  state: {
    data: []
  },
  getters: {
    unpaidData(state: any): any[] {
      if (state.data) {
        return state.data.filter((v: any) => {
          return v.status === 1
        })
      }
      return []
    },
    paidData(state: any): any[] {
      if (state.data) {
        return state.data.filter((v: any) => {
          return v.status === 2
        })
      }
      return []
    },
    totalSales(state: any): string {
      let value = 0
      if (state.data && state.data.length) {
        state.data.map((v: any) => {
          value += v.amount + v.service_amount
        })
      }
      return currencyFormater(value)
    },
    totalPaidSales(state: any): string {
      let value = 0
      if (state.data && state.data.length) {
        state.data.map((v: any) => {
          if (v.status === 2) value += v.amount + v.service_amount
        })
      }
      return currencyFormater(value)
    },
    totalUnpaidSales(state: any): string {
      let value = 0
      if (state.data && state.data.length) {
        state.data.map((v: any) => {
          if (v.status === 1) value += v.amount + v.service_amount
        })
      }
      return currencyFormater(value)
    },
    totalOrder(state: any): number {
      return state.data ? state.data.length : 0
    },
    products(state: any): any[] {
      const products: any[] = []
      if (state.data.length) {
        state.data.forEach((v: any) => {
          v.order_data.forEach((order: any) => {
            const data = products.find((product) => {
              return product.uuid === order.product_id
            })
            
            if (!data) {
              products.push({
                ...order.product,
                ...{
                  quantity: order.quantity,
                  total: order.total
                }
              })
            } else {
              data.quantity += order.quantity
              data.total += order.total
            }
          })
        })
      }
      return products
    },
    totalDiscount(state: any): number {
      let value = 0
      if (state.data) {
        const paidData = state.data.filter((v: any) => {
          return v.status === 2
        })
        
        paidData.map((v: any) => {
          const {invoice} = v
          if(invoice) {
            const {payment} = invoice
            if(payment) {
              value += payment.discount_amount
            }
          }
        })
      }
      return value
    },
    totalServiceCharge(state: any): number {
      let value = 0
      if (state.data) {
        state.data.map((v: any) => {
          value += v.service_amount
        })
      }
      return value;
    },
    totalPbl(state: any): number {
      let value = 0
      if (state.data) {
        state.data.map((v: any) => {
          value += v.tax_pbl_amount
        })
      }
      return value;
    }
  },
  mutations: {
    setData(state: any, value: any): void {
      state.data = value
    }
  },
  actions: {}
}

